import qs from 'qs'
import axios from 'axios'
import NProgress from 'nprogress'
import { Message } from 'element-ui'
import errorCode from '@/config/errorCode.js'
import { serialize } from '@/utils/util'

axios.defaults.timeout = 10000
axios.defaults.baseURL = '/website'
axios.defaults.withCredentials = true
axios.defaults.validateStatus = (status) => {
  return status <= 500
}

NProgress.configure({
  showSpinner: false
})

axios.interceptors.request.use(config => {
  NProgress.start()
  // headers中配置serialize为true开启序列化
  if (config.methods === 'post' && config.headers.serialize) {
    config.data = serialize(config.data)
    delete config.data.serialize
  }

  // 处理get 请求的数组 springmvc 可以处理
  if (config.method === 'get') {
    config.paramsSerializer = function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  }

  return config
}, error => {
  return Promise.reject(error)
})

axios.interceptors.response.use(res => {
  NProgress.done()
  const status = Number(res.status) || 200
  const code = res.data?.code
  const message = res.data.msg || errorCode[status] || errorCode.default

  // 错误的请求
  if (status !== 200 || (code && code !== 200)) {
    Message({
      message: message,
      type: 'error'
    })
    return Promise.reject(new Error(message))
  }
  return res
}, error => {
  NProgress.done()
  return Promise.reject(new Error(error))
})

export default axios
